import React, { useState } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Form, Input, Button, Divider, message } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import { useSession } from "../../../util/Session";
import conditional from "../../../util/conditional";

export default function AccountForgot(props) {


    const { activate = false } = props;

    const loc = useLocation();
    const [, setSession] = useSession();

    let hasToken = false;
    if (loc.search) {
        if (loc.search.includes("?token=")) {
            hasToken = loc.search.replace("?token=", "");
        }
    }

    const [token] = useState(hasToken);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [registered, setRegistered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState("");


    const title = (activate) ? "Verify Your Account" : "Forgotten Password"
    const callback = (activate) ? "account/activate" : "account/forgot";

    // Email 
    const onSendForgotPasswordEmail = (_status, _result) => {
        setLoading(false);
        if (!_status) {
            setError(true);
            if (_result.error === "No account for that email") {
                setErrorMessage(_result.error);
            } else {
                setErrorMessage("There was an error. Please ensure you've entered a valid email address.");
            }

        } else {
            if (_result.success) {
                setEmailSent(true);
            }
        }
    }

    const sendForgotPasswordEmail = (v) => {
        if (loading) { return; }
        setLoading(true);
        setRegistered(false);
        setError(false);
        let { email_address } = v;
        setEmail(email_address);
        apiCall("account/emailPasswordToken", { "email_address": email_address, "callback_url": callback }, onSendForgotPasswordEmail);
    }

    // Update 
    const onUpdatePassword = (_status, _result) => {
        if (_status) {
            message.success('Your password has been successfully changed.', 3);
            setSession(_result);
        } else {
            setError(true);
            setErrorMessage(_result.error);
        }
    }

    const updatePassword = (_values) => {
        apiCall("account/resetPassword", { password: _values.password, token: token }, onUpdatePassword);
    }

    const passwordResetForm = () => {
        return (
            <>
                <div>
                    <h2>Password Change Utility</h2>
                    <div aria-hidden><Divider /></div>

                    <p>You may now enter a new password, minimum 8 characters:</p>
                    <Form onFinish={(values) => updatePassword(values)} layout="vertical">
                        <Form.Item
                            name="password"
                            label="New Password"
                            rules={[{
                                required: true,
                                min: 8,
                                message: 'Password must be at least 8 characters.',
                            },]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[{
                                required: true,
                                message: 'Please confirm your password.',
                            }, ({ getFieldValue }) => ({
                                validator(rule, value) {

                                    if (value.length < 8) {
                                        return Promise.reject('Password must be at least 8 characters.');
                                    }

                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match.');
                                },
                            }),]}
                        >
                            <Input.Password min={8} max={12} />
                        </Form.Item>
                        {(error && <p style={{ "paddingTop": "15px", "color": "#ff4d4f", "textAlign": "center" }} className="error"><ExclamationCircleFilled /> {errorMessage}</p>)}
                        <Button type="primary" htmlType="submit">Change Password</Button>
                    </Form>
                </div>
            </>)
    }

    const displayEmailSentMessage = () => {
        return (
            <>
                <div>
                    <h2>{title}</h2>
                    <div aria-hidden><Divider /></div>
                    <p>An email has been sent to {email}. It will contain instructions on how to {(activate) ? "set" : "reset"} your password. Please check your email now.</p>
                    <p>Note: Check your spam folder in case your email system does not recognize our email address, {window.sitesettings.store_info.email}.</p>
                </div>
            </>
        )
    }

    const display = () => {
        return (
            <div>
                <h2>{title}</h2>
                <div aria-hidden><Divider /></div>
                <conditional.true value={activate}>
                    <p>An account has been created for you on your behalf, but needs to be assigned a password and verified. <br /><br /> Please enter your  email address below, and we will send you a single-use link to activate your account and assign a new password. <br /><br />If no account is found for the entered email address, perhaps try another email, or use the Login box to create a new account.</p>
                </conditional.true>

                <conditional.true value={!activate}>
                    <div>
                        <p>To reset your account's password, we will send an email to the address associated with your account containing a reset link.</p>
                        <small>This email is sent immediately, so if you didn't receive anything, check your junk / spam folder. Your password will only be reset once you click the link within the email, so if you requested a password reset in error or no longer need to reset it, simply disregard the email and do not click the reset link.</small>
                    </div>
                </conditional.true>

                <div style={{ "opacity": (loading) ? "0.7" : "1", "margin": "0 auto", "marginTop": "40px", "maxWidth": "500px" }}>
                    <Form onFinish={(values) => sendForgotPasswordEmail(values)} layout="vertical">
                        <Input.Group compact>
                            <Form.Item name="email_address" noStyle>
                                <Input inputMode="email" size="large" type="email" style={{ "width": '73%' }} placeholder="Email Address" />
                            </Form.Item>
                            <Button htmlType="submit" style={{ "width": '26%' }} size="large" type="primary">{(activate) ? "Activate" : "Retrieve"}</Button>
                        </Input.Group>
                    </Form>
                    {(error && <p style={{ "paddingTop": "15px", "color": "#ff4d4f", "textAlign": "center" }} className="error"><ExclamationCircleFilled /> {errorMessage}</p>)}
                    {(registered && <p>If you remember your password, <Link to="/account/login">login here.</Link> <br />To recover your password <Link to="/account/forgot">click here</Link></p>)}
                    <br />
                </div>
            </div>
        )
    }

    const displayStatus = () => {
        if (token) {
            return passwordResetForm();
        }
        if (emailSent) {
            return displayEmailSentMessage();
        }
        return display();
    }

    return displayStatus();

}