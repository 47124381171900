import React, { useEffect, useState } from "react";
import { Modal, Card, Button, Space, Alert, Divider } from 'antd';
import { apiCall } from "./utilities/Api";
import Cookies from 'js-cookie';
import { LocationIcon } from "../util/Icons";
import { useSession } from "../util/Session";
import conditional from "./utilities/conditional";
import { useHistory } from "react-router-dom";

export default function LocationSelector(props) {
    const history = useHistory(); 
    const [session, setSession] = useSession();
    const [selected, setSelected] = useState(session.location_id);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const loadData = (_popup = false) => {

        if (!dataLoaded) {
            apiCall("webstore/getContactAndHours", {}, onDataLoaded);
            if(_popup){
                setModalVisible(true);
            }
        } else {
            setModalVisible(true);
        }
    }

    const onDataLoaded = (_status, _results) => {

        //session.location_id

        if (_status) {

            if (selected && !props.location_btn && session.logged_in) {
                setData(_results);
                setDataLoaded(true);
                setLocation(selected, _results);
            } else {

                setData(_results);

                if(Cookies.get("reloading")){
                    Cookies.remove("reloading")
                } else {

                    if(!window.sitesettings.store_info.multistore_popup_disabled && !session.location_id){
                        setModalVisible(true);
                    }
                    
                }
                
                setLocation(session.location_id, _results);
                setDataLoaded(true);
            }
        }
    }

    const checkIfMultistore = () => {

        if (!window.sitesettings.store_info.is_multistore) {
            return false;
        }

        if (!window.sitesettings.store_info.is_consolidated_multistore) {
            return false;
        }

        return true;

    }


    const loadSelector = () => {

        apiCall("webstore/getContactAndHours", {}, (_s, _r) => {
            if(_s){
                setData(_r);
            }
        });
       setModalVisible(true)
    }

    const setLocation = (_location_id, _data) => {

        // set the id to the settings id if there is no location passed. 
        if(!_location_id){
            _location_id = window.sitesettings.store_info.id;
        }

        let index;
        for (let i = 0; i < _data.length; i++) {
            if (_data[i].location_id === _location_id) {
                index = i;
            }
        }


            window.sitesettings.store_info.name = (_data[index].altname) ? _data[index].altname : _data[index].sc_name;
            window.sitesettings.store_info.email = _data[index].sc_email;
            window.sitesettings.store_info.phone = _data[index].sc_phone;
            window.sitesettings.store_info.phone_2 = _data[index].sc_phone_2;
            window.sitesettings.store_info.address = _data[index].sc_addr_array.address;
            window.sitesettings.store_info.city = _data[index].sc_addr_array.city;
            window.sitesettings.store_info.province = _data[index].sc_addr_array.prov;
            window.sitesettings.store_info.postal_code = _data[index].sc_addr_array.postal;
            window.sitesettings.store_info.country = _data[index].sc_addr_array.country;
            window.sitesettings.store_info.latitude = _data[index].lat_lng.lat;
            window.sitesettings.store_info.longitude = _data[index].lat_lng.lng;

    
       
        window.ws_hours = _data[index].ws_hours;
        window.ws_htmlhours = _data[index].ws_htmlhours;
        window.ws_contact_blurb = _data[index].ws_contact_blurb;


        setSession({ ...session });

    }

  

    const displayModal = () => {

        return (
            <>

                <Modal footer={null} onCancel={() => { (session.location_id && setModalVisible(false)) }} closable={(session.location_id)} open={modalVisible}>

                    <conditional.true value={(true)}>
                        <h3>Please select a location</h3>
                        <div aria-hidden><Divider style={{"marginBottom" : "10px"}} /></div>
                        
                    </conditional.true>
                    {/* <conditional.true value={(session.location_id)}>
                        <div className="shim"></div> <div className="shim"></div> <div className="shim"></div> <div className="shim"></div> <div className="shim"></div>
                    </conditional.true> */}
                    <conditional.true value={(true)}>
                        <div style={{"color" : "#e70d3d", "border" : "1px dashed #e70d3d", "padding" : "10px"}}>Please be aware that product availability, including those on hand or on order, may change when switching locations. Make sure to review your cart.</div>
                        
                        <div className="shim"></div><div className="shim"></div>
                    </conditional.true>
                    <DisplayOptions />
                </Modal>
            </>
        )

    }

    const LocationItems = (props) => {

        return data.map((contact, index) => {

            let { altname, sc_addr_array, san, location_id } = contact;
            let { address, city, prov, postal, country } = sc_addr_array

            let _find = window.sitesettings.store_info.multistore_info.find(item => item.id === location_id.toString());
            let message = "";
            if(_find){ message = _find.message; }

            return (
                <>
                {message}
                <Card key={index} onClick={() => saveStore(san, location_id)} style={{ "padding": "0px", "cursor": "pointer" }}>
                   
                    <strong>{altname}</strong><br />
                    <span>{address}</span><br />
                    <span>{city}, {prov} {postal}</span><br />
                    <span>{country}</span><br /><br />
                    <Button type={(selected === location_id) ? "primary" : "default"}>{(selected === location_id) ? "Selected" : "Select"}</Button>
                </Card>
                </>
            )
        })

    }

    const DisplayOptions = (props) => {
        return (
            <>
                <div style={{ "gridGap": "10px" }} className="wrapper">{<LocationItems close={props.close} />}</div>
            </>
        )
    }

    const onSetMultistoreLocation = (_status, _result) => {
        if (_status) {

            if(!session.logged_in){
                Cookies.set("reloading", true);
            }
           
            window.location.reload();
        }
    }

    const saveStore = (_san, _loc_id) => {

        if (_loc_id !== session.location_id) {
            apiCall("webstore/setMultistoreLocation", { location_id: _loc_id }, onSetMultistoreLocation);

            // Cookies.set("selected_store_" + window.sitesettings.store_info.san, _san);
            setSelected(_loc_id);

            setLocation(_loc_id, data);
        }

        setModalVisible(false);
    }

    const display = () => {

        let _name = window.sitesettings.store_info.name;
        let _address = window.sitesettings.store_info.address;


        
        if(props.checkout){
            return(
                <>
                {displayModal()}
                <span onClick={() => loadSelector()} style={{ "cursor": "pointer" }}>
                    {props.children}
                </span>
                </>
                )
        }

     

        if(props.wrap){

            return(
            <>
            {displayModal()}
            <span onClick={() => loadData()} style={{ "cursor": "pointer" }}>
                {props.children}
            </span>
            </>
            )
        }

        return (
            <>
                {displayModal()}
                {(props.location_btn && <>
                    <div role="button" tabIndex={0} aria-label={"Location Selection " + _name} onClick={() => loadData(true)} style={{ "cursor": "pointer" }} className="hide"><Space><LocationIcon aria-hidden className="icon-btn bm-icon" style={{ "paddingRight": "0px" }} /><span><div className="store_name bm-icon" style={{ "display": "block", "lineHeight": "12px", "whiteSpace": "nowrap", "paddingRight": "14px", "paddingLeft": "0px", "textOverflow": "ellipsis", "overflow": "hidden" }}><small aria-hidden><strong>{_name}</strong><br />{_address}</small></div></span></Space></div>
                    <div role="button" tabIndex={0} aria-label={"Location Selection " + _name} onClick={() => loadData(true)} style={{ "cursor": "pointer" }} className="show"><LocationIcon className="icon-btn bm-icon" /></div>
                </>)}
            </>
        )
    }

    const initLoadData = () => {

        if(props.checkout){
            return; 
        }

        if(history.location.pathname.includes("/verify")){
            return;
        }

        if(props.wrap){
            return;
        }

        if (!props.location_btn) {
            loadData();
        }

    }

    useEffect(initLoadData, []);

    const displayStatus = () => {
        if (!checkIfMultistore()) return (<></>);
        return display();
    }

   

    return displayStatus();

}